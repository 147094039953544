import React from "react";
import { Tasks } from "../components/Tasks";
import { Footer } from "../components/Footer/Footer";

export const TasksPage = () => {
  return (
    <>
      <Tasks />
      <Footer />
    </>
  );
};
