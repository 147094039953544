import React from "react";
import { Planer } from "../components/Planer";
import { Footer } from "../components/Footer/Footer";

export const PlanerPage = () => {
  return (
    <>
      <Planer />
      <Footer />
    </>
  );
};
